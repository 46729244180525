.container{
     text-align: center;
     background: black;
     height: 100vh;
     display: flex;
}
@media (max-width: 800px) {
  .container {
    flex-direction: column;
  }
}

.wrapper{
  background-color: white;
  /* width: 600px; */
  display: flex;
  width: 100%; 
  flex-direction: column;
}
h1{
  font-size: 60px;
  font-weight: 600;
  text-align: center;
  color: purple;
}
h3{
  font-size:25px;
  text-align: center;
  color:rgb(238, 131, 8)
}

input{
  margin:10px;
  padding: 10px;
  border: 1px solid #050000;
  border-radius: 4px;
}

input[type=submit]{
  display: grid;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: #445A6F;
  border: 1px solid #445A6F;
  margin:auto;
  color: #fff;
  font-size: 15px;
}
input[type="submit"]:hover {
  background-color: #45a049;
  font-size: 25px;

}
.form{
  flex-direction: column;
  text-align: center;
  border: 5px solid;
  left: 50%;
  margin-left:30px;
  margin-right:30px;
  margin-bottom:100px;
  padding: 20px;
}
.progress{
  margin:20px
}

.center {
  text-align: center;
  border: 5px solid;
  left: 50%;
  margin:100px;
  padding: 20px;
}
.link{
  max-width: 1200px; 
  word-wrap: break-word;
}